import { motion } from 'framer-motion'

const AnimatedPage = ({ children }) => {

    const stylesAuth = ["col-10", "col-sm-10", "col-md-8", "col-lg-7", "col-xl-6"];
    const stylesShop = ["col-12", "col-lg-10"];

    const animation = {
        initial: {
            opacity: 0,
            backdropFilter: 'blur(5px)'
        },
        animate: {
            opacity: 1
        },
        exit: {
            opacity: 0,
            backdropFilter: 'blur(5px)'
        }
    };

    const classNames = children.key === 'Auth' 
    ? stylesAuth.join(' ') 
    : children.key === 'Shop' 
        ? stylesShop.join(' ') 
        : 'col-10 col-md-9';

    return ( 
        <motion.div
            variants={animation} 
            initial="initial" 
            animate="animate"
            exit="exit"
            className={`d-flex justify-content-center my-auto ${classNames}`}>
            <main className={"align-items-center bg-home my-auto"}>
                {children}
            </main>
        </motion.div>
    )
}

export default AnimatedPage;
