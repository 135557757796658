import { configureStore } from '@reduxjs/toolkit';
import cartReducer from './slicer/cartSlice';
import productsReducer from './slicer/shopSlice'
import modalReducer from './slicer/modalSlice';
import cardsReducer from './slicer/cardsSlice';
import addressReducer from './slicer/addressSlice';

const store = configureStore({
  reducer: {
    cart: cartReducer,
    shop: productsReducer,
    modal: modalReducer,
    cards: cardsReducer,
    address: addressReducer,
  },
});

export default store;
