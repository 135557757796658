import React from 'react'
import { Link } from 'react-router-dom'
import './Home.css'
import AnimatedPage from '../AnimatedPage'
import AnimatedBtn from '../AnimatedBtn'
import Scrollbars from 'react-custom-scrollbars-2'

export default function Home() {

    const verticalScrollbarStyle = {
        backgroundColor: 'rgba(238, 203, 113, 0.863)',
        borderRadius: '10px'
      };  

  return (
    <AnimatedPage>
        <div>
            <h1 className="text-center py-1 text-light shadow-text">GoldenLeaf</h1>
        </div>
        <Scrollbars style={{width: '100%', height: '50vh'}} 
            renderThumbVertical={({ style, ...props }) =>
              <div {...props} style={{ ...style, ...verticalScrollbarStyle}}/>
            }
            renderTrackHorizontal={(props) => (
              <div
              {...props} style={{ display: 'none' }}/>
            )}
        >
        <div className="row col-10 mx-auto text-light text-center shadow-text h-100 align-items-center home-text">
            <p>
                Bienvenue chez GoldenLeaf, où l'excellence des cannabinoïdes se conjugue avec l'art de la relaxation et de l'épanouissement personnel.<br /> Explorez notre collection exquise de fleurs et de résines de CBD, THCP et HHCPO, soigneusement sélectionnées pour vous offrir une expérience incomparable.<br />

                Le CBD, ou cannabidiol, est la pierre angulaire de notre sélection. Connu pour ses propriétés apaisantes et relaxantes, le CBD est votre allié idéal pour apaiser le stress, soulager les tensions musculaires et favoriser un sommeil réparateur. Plongez dans un océan de tranquillité avec nos produits de CBD, et laissez-vous emporter par une vague de bien-être.<br />

                Découvrez également le THCP, une molécule aux promesses intrigantes. Avec des effets potentiellement plus puissants que le THC, le THCP offre un soulagement efficace de la douleur et une relaxation profonde. Laissez-vous séduire par ses possibilités et explorez de nouveaux horizons de détente et de confort.<br />

                Enfin, notre sélection de produits HHCPO offre une expérience unique, associant relaxation et clarté mentale. Parfait pour ceux qui recherchent un équilibre entre la tranquillité et la vivacité, le HHCPO vous accompagne dans votre quête d'harmonie intérieure et de bien-être global.<br />

                Chez GoldenLeaf, nous nous engageons à vous offrir des produits de la plus haute qualité, cultivés avec soin et attention pour vous garantir une expérience exceptionnelle à chaque utilisation. Rejoignez-nous dans cette exploration des cannabinoïdes et découvrez le potentiel transformateur de la nature à portée de main.<br />

                Laissez GoldenLeaf être votre guide vers un monde de relaxation, de confort et d'épanouissement. Parce que chaque instant de bien-être compte, et chaque produit GoldenLeaf vous rapproche un peu plus de votre meilleur vous-même.<br />
            </p>
        </div>
        </Scrollbars>
        <div className="row my-2 justify-content-center">
            <div className="row justify-content-center">
                <div className="col-4 d-flex justify-content-center home-btn">
                    <AnimatedBtn>
                        <Link to="/shop/">
                            <button type="submit" className="btn-goldenleaf text-center mx-auto">
                                <span className="text-center">Découvrir</span>
                            </button>
                        </Link>
                    </AnimatedBtn>
                </div>
            </div>
        </div>
    </AnimatedPage>
  )
}
