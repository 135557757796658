import React, { useState, useEffect } from 'react'
import ReactLoading from "react-loading";
import Logo from '../src/assets/img/logo.webp'
import App from './App';
import { ObiWanFlower, JackHererFlower, LemonHazeFlower, BlueBerryFlower, AmnesiaFlower, backGround } from './productImage';

export default function Loading() {
  const [isLoading, setIsLoading] = useState(true);

  const preloadImages = (images) => {
    const promises = [];
    for (let i = 0; i < images.length; i++) {
      const img = new Image();
      promises.push(
        new Promise((resolve, reject) => {
          img.onload = resolve;
          img.onerror = reject;
          img.src = images[i];
        })
      );
    }
    return Promise.all(promises);
  };

  useEffect(() => {
    preloadImages([ObiWanFlower, JackHererFlower, LemonHazeFlower, BlueBerryFlower, AmnesiaFlower, backGround]).then(() => {
      setIsLoading(false);
    });
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="App">
          <div className='h-100 align-items-center justify-content-center d-flex bg-loading' style={{position: "relative"}}>
            <div className='d-flex flex-column my-auto justify-content-between'>
                <div className='d-flex justify-content-center img-loading'>
                    <img src={Logo} width="auto" height="170px" alt='Logo GoldenLeaf'/>
                </div>
                <h1 className='titleLoading'>GoldenLeaf</h1>
                <div className='d-flex justify-content-center'>
                    <ReactLoading type='cylon' color="rgb(252, 205, 88)"
                    height={179} width={86} />
                </div>
            </div>
          </div>
        </div>
      ) : (
        <App />
      )}
    </>
  )
}