import { motion } from 'framer-motion'

const AnimatedSlider = ({children}) => {

const animation = {
    initial: {
        opacity: 0
    },
    animate: {
        opacity: 1,
        transition: {
          delay: 0.8,
          duration: 0.5
      }
    },
    exit: {
        opacity: 0
    },
}

  return ( 
    <motion.div
    variants={animation} 
    initial="initial"
    animate="animate"
    exit="exit">
      {children}
    </motion.div>
  )
}

export default AnimatedSlider;