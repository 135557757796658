import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addCartItem } from '../redux/slicer/cartSlice';
import { updateProducts } from '../redux/slicer/shopSlice';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { db } from '../firebase-config';
import {
  collection,
  onSnapshot,
  query,
  where
} from 'firebase/firestore';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas, faBagShopping } from '@fortawesome/free-solid-svg-icons';
import AnimatedPage from '../AnimatedPage';
import AnimatedSubPage from '../AnimatedSubPage';
import { v4 as uuidv4 } from 'uuid';
import { ObiWanFlower, JackHererFlower, LemonHazeFlower, BlueBerryFlower, AmnesiaFlower } from '../productImage';
import { UserContext } from '../Context/UserContext'


library.add(fas, faBagShopping);

export default function Shop() {
  const [category, setCategory] = useState('CBDFlower');
  const {currentUser} = useContext(UserContext);
  const uid = currentUser ? currentUser.uid : null

  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState([]);
  const [discounts, setDiscounts] = useState([]);

  useEffect(() => {
    const q = query(collection(db, 'reduction_price'));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const discountsList = {};
      snapshot.forEach((doc) => {
        discountsList[doc.id] = doc.data();
      });
      setDiscounts(discountsList);
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    const q = query(collection(db, 'produits'), where('category', '==', category));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const productsList = [];
      snapshot.forEach((doc) => {
        productsList.push({ id: doc.id, ...doc.data() });
      });
      dispatch(updateProducts(productsList));
    });
    return unsubscribe;
  }, [category, dispatch]);

  const products = useSelector((state) => state.shop);

  const verticalScrollbarStyle = {
    backgroundColor: 'rgba(238, 203, 113, 0.863)',
    borderRadius: '10px'
  };

  const addToCart = (item) => {
    if (item.grQuantity === undefined) {
      item.grQuantity = "1";
      item.reductionPrice = "1";
    }
    dispatch(addCartItem({ cartItem: item, uid: uid }));
  }

  const productImage = (product) => {
    const name = product.pictureName;

    switch (name) {
      case 'ObiWanFlower':
        return ObiWanFlower;
      case 'JackHererFlower':
        return JackHererFlower;
      case 'LemonHazeFlower':
        return LemonHazeFlower;
      case 'BlueBerryFlower':
        return BlueBerryFlower;
      case 'AmnesiaFlower':
        return AmnesiaFlower;
      default:
        return AmnesiaFlower;
    }
  };

  const handleCategoryChange = (category) => {
    setCategory(category);
  };

  const totalPrice = (product) => {
    if (quantity[product.id] > 1) {
      const count = quantity[product.id];
      const reduction = Object.values(discounts)[0][count];
      const finalPrice = (product.prix * count) * reduction;
      return finalPrice.toFixed(2);
    }
    return product.prix;
  };

  const unitPrice = (product) => {
    if (quantity[product.id] > 1) {
      const count = quantity[product.id];
      const reduction = Object.values(discounts)[0][count];
      const finalPrice = product.prix * reduction;
      return finalPrice.toFixed(2);
    }
    return product.prix;
  };

  return (
    <AnimatedPage className="w-10">
      <div key="Shop">
      <h1 className="text-center mt-2 text-light mb-1 shadow-text">Les produits</h1>
      <div className='container products-overflow text-light shadow-text mt-3 mb-2'>
        <DropdownButton title="Catégories" variant="secondary" className="mx-2 mb-2" id='dropdown-btn'>
          <Dropdown.Item active={category === 'CBDFlower'} onClick={() => handleCategoryChange('CBDFlower')}>Fleurs de CBD</Dropdown.Item>
          <Dropdown.Item active={category === 'CBDResin'} onClick={() => handleCategoryChange('CBDResin')}>Résines de CBD</Dropdown.Item>
          <Dropdown.Item active={category === 'HHCFlower'} onClick={() => handleCategoryChange('HHCFlower')}>Fleurs de HHC</Dropdown.Item>
          <Dropdown.Item active={category === 'HHCResin'} onClick={() => handleCategoryChange('HHCResin')}>Résines de HHC</Dropdown.Item>
        </DropdownButton>
        <AnimatedSubPage>
          <Scrollbars style={{width: '100%', height:'70vh', maxHeight: '70vh'}} 
            renderThumbVertical={({ style, ...props }) =>
              <div {...props} style={{ ...style, ...verticalScrollbarStyle}}/>
            }
            renderTrackHorizontal={(props) => (
              <div
              {...props} style={{ display: 'none' }}/>
            )}
          >
          <div className='row justify-content-center'>
          {products.map((product) => (
            <div key={uuidv4()} className="card col-xxl-3 col-xxl-3_5 col-10 col-md-5 m-2 p-2">
              <div className='row justify-content-center'>
                <div className='row justify-content-end'>
                  <div className='col-4 d-flex justify-content-end'>
                    <p style={{fontSize: "1.25rem"}} className="price text-truncate">{unitPrice(product)}€/gr</p>
                  </div>
                </div>
                <div className='row justify-content-center'>
                  <div className='col-8 d-flex justify-content-center'>
                    <img className="" style={{height: "6.5rem", width: "auto"}} src={productImage(product)} alt={product.nom} />
                  </div>  
                </div>
              </div>           
              <div className="card-body">
                <h5 className="card-title text-truncate">{product.nom}</h5>
                <p className="card-text text-truncate">{product.description}</p>
                <div className="d-flex align-items-center justify-content-between">
                  <select
                    style={{width: "25%", boxShadow: "none"}}
                    className="form-select"
                    id="quantity"
                    value={quantity[product.id] || ""}
                    onChange={(e) => setQuantity({...quantity, [product.id]: e.target.value})}>
                    <option value="1" defaultValue>1gr</option>
                    <option value="5">5gr</option>
                    <option value="10">10gr</option>
                    <option value="20">20gr</option>
                    <option value="50">50gr</option>
                    <option value="100">100gr</option>
                  </select>
                  <p className="card-text price text-truncate">{totalPrice(product)}€</p>
                  <button 
                  className='no-css-btn icon-golden' 
                  onClick={() => addToCart({...product, cartId: uuidv4(), quantity: 1, grQuantity: quantity[product.id], reductionPrice: Object.values(discounts)[0][quantity[product.id]]})}>
                    <FontAwesomeIcon icon={["fa-solid", "fa-cart-shopping"]} />
                  </button>
                </div>
              </div>
            </div>
          ))}
          </div>
          </Scrollbars>
        </AnimatedSubPage>
      </div>
    </div>
    </AnimatedPage>
  );
}