import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Loading from './Loading';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { UserContextProvider } from './Context/UserContext';
import { Provider } from 'react-redux';
import store from './redux/redux';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <UserContextProvider>
      <BrowserRouter>
        <Loading />
      </BrowserRouter>
    </UserContextProvider>
  </Provider>
);
reportWebVitals();
