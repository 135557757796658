import React, {useRef, useState, useContext, useEffect} from 'react'
import { UserContext } from '../Context/UserContext';
import { useNavigate } from 'react-router-dom';
import AnimatedSubPage from '../AnimatedSubPage';
import AnimatedBtn from '../AnimatedBtn';
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch } from 'react-redux';
import {
  setDoc,
  doc,
  collection,
  updateDoc
} from 'firebase/firestore';
import { db } from '../firebase-config';

export default function Registration() {

  const recaptchaRef = React.useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {signUp, setName, currentUser} = useContext(UserContext)
  const [validation, setValidation] = useState();
  const [success, setSuccess] = useState();
  const uid = currentUser ? currentUser.uid : null

  const inputs = useRef([]);
  const formRef = useRef();

  const addInputs = el => {
    if(el && !inputs.current.includes(el)) {
      inputs.current.push(el);
    }
  }

  useEffect(() => {
    if (uid) {
      const cart = localStorage.getItem("cartItems");
      const userRef = doc(collection(db, 'users'), uid);
      setDoc(userRef, { email: currentUser.email }, { merge: true });
      setDoc(userRef, { products: [] }, { merge: true });
      setDoc(userRef, { cards: [] }, { merge: true });
      setDoc(userRef, { address: [] }, { merge: true });
      if (cart) {
        updateDoc(userRef, {products: JSON.parse(cart)});
        localStorage.removeItem("cartItems");
      }
    }
  }, [uid, dispatch, currentUser]);

  const handleForm = async (e) => {
    e.preventDefault();
    const token = await recaptchaRef.current.executeAsync();
    setSuccess('');
    setValidation('');

    if (inputs.current[2].value.length !== inputs.current[3].value.length) {
      setValidation('Vous devez entrer deux fois le même mot de passe.');
      return;
    } else if (inputs.current[2].value.length <= 6) {
      setValidation('Votre mot de passe doit contenir 6 caractères min.');
      return;
    }
    if (token) {                                                                                                            
      try {
        await signUp(inputs.current[1].value, inputs.current[2].value);
        await setName({ displayName: inputs.current[0].value });
        formRef.current.reset();
        setValidation('');
        setSuccess("Votre compte à bien été crée ! Redirection en cours...");
        const timer = setTimeout(() => {
          navigate('/user/profile');
        }, 2000);
        return () => clearTimeout(timer);
      } catch (err) {
        if (err.code === 'auth/invalid-email') {
          setValidation("Le format de l'adresse e-mail est invalide.");
        }
        if (err.code === 'auth/email-already-in-use') {
          setValidation('Cette adresse e-mail est déjà enregistrée.');
        }
      }
    } else {
      setValidation("Erreur de captcha, veuillez réessayer.")
    }
  };

  return (
    <AnimatedSubPage>
        <div>
            <h2 className="text-center mt-5 text-light shadow-text">Inscription</h2>
        </div>
        <div className="row text-light text-center mb-2 shadow-text">
          <form
          ref={formRef}
          onSubmit={handleForm}
          className='p-3 px-5'>
            <div className="form-group p-2">
              <label htmlFor="name">Votre Nom</label>
              <input
              required
              type="text" 
              className="form-control" 
              id="name" 
              placeholder='Nom & Prénom'
              ref={addInputs} />
            </div>
            <div className="form-group p-2">
              <label htmlFor="email">Adresse e-mail</label>
              <input
              required
              type="email" 
              className="form-control" 
              id="email" 
              aria-describedby="emailHelp" 
              placeholder='Entrez une adresse e-mail valide'
              ref={addInputs} />
            </div>
            <div className="form-group p-2">
              <label htmlFor="pass">Mot de passe</label>
              <input 
              required
              type="password" 
              className="form-control" 
              id="pass"
              ref={addInputs} />
            </div>
            <div className="form-group p-2">
              <label htmlFor="passVerify">Vérification du mot de passe</label>
              <input 
              required
              type="password" 
              className="form-control" 
              id="passVerify"
              ref={addInputs} />
            </div>
            <p className='text-danger mt-1 validation-p'>{validation}</p>
            <p className='text-success mt-1 validation-p'>{success}</p>
            <AnimatedBtn>
              <button type="submit" className="btn-goldenleaf text-center mx-auto mt-4">
                  <span className="text-center">S'inscrire</span>
              </button>
            </AnimatedBtn>
            <ReCAPTCHA
              className='m-auto mt-2 justify-content-center d-flex'
              size="invisible"
              sitekey={process.env.REACT_APP_SITE_KEY}
              ref={recaptchaRef}
            />
          </form>
        </div>
    </AnimatedSubPage>
  )
}
