import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { db } from '../../firebase-config';
import { doc, getDoc, updateDoc } from 'firebase/firestore';

export const deleteCard = createAsyncThunk(
  'cards/deleteCard',
  async ({ userId, cardId }, { rejectWithValue }) => {
    try {
      const userDoc = doc(db, 'users', userId);
      const userSnap = await getDoc(userDoc);
      if (!userSnap.exists()) {
        throw new Error('User not found');
      }

      const user = userSnap.data();
      const updatedCards = user.cards.filter(card => card.id !== cardId);

      await updateDoc(userDoc, {
        cards: updatedCards
      });

      return cardId;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

const cardsSlice = createSlice({
  name: 'cards',
  initialState: [],
  reducers: {
    updateCards: (state, action) => {
      return action.payload;
    },
    deleteCard: (state, action) => {
      return state.filter(card => card.id !== action.payload);
    },
  },
});

export const { updateCards } = cardsSlice.actions;

export default cardsSlice.reducer;

