import './App.css';
import Nav from './Nav/Nav';
import 'bootstrap/dist/css/bootstrap.css';
import { Route, Routes, useLocation } from 'react-router-dom';
import Home from './Home/Home';
import Order from './Order/Order';
import Shop from './Shop/Shop'
import Authentication from './Authentication/Authentication';
import User from './User/User';
import NotFound from './404/NotFound';
import LogIn from './Authentication/LogIn';
import Registration from './Authentication/Registration';
import Profile from './User/Profile';
import AskNewPass from './Authentication/AskNewPass';
import SetNewPass from './Authentication/SetNewPass';
import AdminPage from './admin/AdminPage';
import Product from './admin/Product';
import Cart from './Shop/Cart';
import { AnimatePresence } from 'framer-motion';
import { Scrollbars } from 'react-custom-scrollbars-2';
import ProductNavbar from './Nav/ProductNavbar'
import AnimatedSlider from './AnimatedSlider';
import { UserContext } from './Context/UserContext';
import { useState } from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from './firebase-config';
import { useDispatch } from 'react-redux';
import { fetchCartItems } from './redux/slicer/cartSlice';
import RegisteredCards from './User/RegisteredCards';
import Help from './User/Help';
import Address from './User/Address';
import CookieConsent from "react-cookie-consent";

function App() {
  const location = useLocation()
  const heightHome = location.pathname === "/" ? 'main-container-home' : 'main-container';
  const verticalScrollbarStyle = {
    backgroundColor: 'rgba(238, 203, 113, 0.863)',
    borderRadius: '10px',
    marginRight: '0px!important',
    marginBottom: '0px!important'
  };

  const [isAdmin, setIsAdmin] = useState(false);
  const { currentUser } = useContext(UserContext);
  const dispatch = useDispatch();
  const uid = currentUser ? currentUser.uid : null

  useEffect(() => {
    dispatch(fetchCartItems(uid));
  }, [dispatch, uid]);

  useEffect(() => {
    const adminCheck = async () => {
      if (currentUser) {
        const rankRef = collection(db, 'rank_admin');
        const userMail = currentUser.email;
        const q = query(rankRef, where('e-mail', '==', userMail));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          setIsAdmin(true);
        } else {
          setIsAdmin(false);
        }
      } else {
        setIsAdmin(false)
      }
    }
    adminCheck();
  })

  return (
    <div className="App">
      <Scrollbars style={{width: '100vw', height: '100vh'}}
            renderThumbVertical={({ style, ...props }) =>
              <div {...props} style={{ ...style, ...verticalScrollbarStyle}}/>
            }
            renderTrackHorizontal={(props) => (
              <div
              {...props} style={{ display: 'none' }}/>
            )}
      >
        <header>
          <Nav />
        </header>
        <div className={`main-container container p-2 d-flex my-auto justify-content-center ${heightHome}`}>
          <AnimatePresence mode='wait'>
            <Routes key={location.pathname} location={location}>
              <Route path='/' element={<Home />} />
              <Route path='/order' element={<Order />} />
              <Route path='/shop' element={<Shop />} />
              <Route path='/authentication' element={<Authentication />}>
                <Route path='/authentication/LogIn' element={<LogIn />} />
                <Route path='/authentication/Registration' element={<Registration />} />
                <Route path='/authentication/AskNewPass' element={<AskNewPass />} />
                <Route path='/authentication/SetNewPass' element={<SetNewPass />} />
              </Route>
              <Route path='/admin' element={isAdmin ? <AdminPage /> : <NotFound />}>
                <Route path='/admin/AddProduct' element={isAdmin ? <Product /> : <NotFound />} />
              </Route>
              <Route path='/user' element={<User />}>
                <Route path='/user/profile' element={<Profile />}/>
                <Route path='/user/registeredcards' element={<RegisteredCards />}/>
                <Route path='/user/help' element={<Help />}/>
                <Route path='/user/address' element={<Address />}/>
              </Route>
              <Route path='/*' element={<NotFound />} />
            </Routes>
          </AnimatePresence>
        </div>
        <CookieConsent
            location="bottom"
            buttonText="Accepter"
            cookieName="myCookie"
            style={{ background: "rgba(0, 0, 0, 0.93)", backdropFilter: "blur(7px)"}}
            buttonStyle={{ background: "linear-gradient(90deg, rgba(203,200,92,0.8) 0%, rgba(174,137,0,0.8) 45%)", fontWeight: "bold", fontFamily: "Alegreya Sans SC, sans-serif", fontSize: "1.5rem"}}
            expires={150}
            >
            <em style={{fontSize: "1.4rem"}}>Ce site à besoin des cookies pour fonctionner 🍪</em>
        </CookieConsent>
        <AnimatedSlider>
          {location.pathname === "/" && <ProductNavbar />}
        </AnimatedSlider>
      </Scrollbars>
      <Cart />
    </div>
  );
}

export default App;
