import React, {useRef, useState, useContext} from 'react'
import { UserContext } from '../Context/UserContext';
import { useNavigate } from 'react-router-dom';
import AnimatedBtn from '../AnimatedBtn';
import AnimatedSubPage from '../AnimatedSubPage';

export default function SetNewPass() {
  const navigate = useNavigate();
  const {resetPass} = useContext(UserContext);
  const [validation, setValidation] = useState("");
  const [success, setSuccess] = useState("");
  const inputs = useRef([]);
  const formRef = useRef();
  const code = new URLSearchParams(window.location.search).get("oobCode");

  const addInputs = el => {
    if(el && !inputs.current.includes(el)) {
      inputs.current.push(el);
    }
  }

  const handleForm = async (e) => {
    e.preventDefault()
    setValidation("");
    if(inputs.current[0].value !== inputs.current[1].value) {
      setValidation("Les deux mots de passe entrés sont différents");
      return;
    }
    try{
      await resetPass(code, inputs.current[0].value);
      setSuccess("Votre mot de passe a été réinitialisé avec succès, Redirection en cours...");
      const timer = setTimeout(() => {
        navigate("/authentication/logIn");
      }, 2000);
      return () => clearTimeout(timer);
    } catch {
      setValidation("Erreur lors de la réinitialisation du mot de passe.");
    }
  }

  return (
    <AnimatedSubPage>
        <div>
            <h2 className="text-center mt-5 text-light shadow-text">Récupération de mot de passe</h2>
        </div>
        <div className="row text-light text-center mb-2 shadow-text">
          <form 
          ref={formRef}
          onSubmit={handleForm}
          className='p-3 px-5'>
            <div className="form-group p-2">
              <label htmlFor="pass">Votre nouveau mot de passe</label>
              <input
              ref={addInputs}
              type='password'
              className="form-control" 
              id="pass" 
              aria-describedby="firstPass" />
            </div>
            <div className="form-group p-2">
              <label htmlFor="confirmPass">Confirmation du mot de passe</label>
              <input
              ref={addInputs}
              type='password'
              className="form-control" 
              id="confirmPass" 
              aria-describedby="confirmPass" />
            </div>
            <p className='text-danger mt-1 validation-p'>{validation}</p>
            <p className='text-success mt-1 validation-p'>{success}</p>
            <AnimatedBtn>
              <button type="submit" className="btn-goldenleaf text-center mx-auto mt-4">
                  <span className="text-center">Valider le Changement</span>
              </button>
            </AnimatedBtn>       
          </form>
        </div>
    </AnimatedSubPage>
  )
}
