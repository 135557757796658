import React, { useState } from 'react';
import AnimatedSubPage from '../AnimatedSubPage';
import AnimatedBtn from '../AnimatedBtn';
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from '@emailjs/browser';

export default function Help() {
  const [validation, setValidation] = useState("");
  const [success, setSuccess] = useState("");
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const recaptchaRef = React.useRef();

  const handleForm = async (e) => {
    e.preventDefault();
    const token = await recaptchaRef.current.executeAsync();
    const { name, email, message } = formData;

    if (token) {
      try {
        await emailjs.send('service_h65isdg', 'template_iiwztri', {
          from_name: name,
          from_email: email,
          message: message,
        }, process.env.REACT_APP_EMAILJS_KEY);
        
        setFormData({
          name: '',
          email: '',
          message: ''
        });
        setValidation("");
        setSuccess("Votre message a bien été envoyé, nous vous répondrons dans les plus brefs délais.");
      } catch (error) {
        console.error('Erreur lors de l\'envoi de l\'e-mail :', error);
        setValidation("Une erreur s'est produite lors de l'envoi du message. Veuillez réessayer.");
      }
    } else {
      setValidation("Erreur de captcha, veuillez réessayer.");
    }
  }

  return (
    <AnimatedSubPage>
      <div>
        <h2 className="text-center mt-5 text-light shadow-text">Contacter le support</h2>
      </div>
      <div className="row text-light text-center mb-2 shadow-text">
        <form onSubmit={handleForm} className='p-3 px-5'>
          <div className="form-group p-2">
            <label htmlFor="name">Nom et Prénom</label>
            <input
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              required
              type="text"
              className="form-control"
              id="name"
              aria-describedby="cardHelp"
              placeholder='Veuillez entrer votre nom complet' />
          </div>
          <div className="form-group p-2">
            <label htmlFor="email">E-mail</label>
            <input
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              required
              type="email"
              className="form-control"
              placeholder="Nous vous répondrons à cette adresse e-mail."
              id="email" />
          </div>
          <div className="form-group p-2">
            <label htmlFor="message">Votre demande</label>
            <textarea
              onChange={(e) => setFormData({ ...formData, message: e.target.value })}
              required
              placeholder="Donnez-nous le plus de détails possible sur votre problème."
              type="text"
              className="form-control"
              rows="10"
              id="message" />
          </div>
          <AnimatedBtn>
            <div className='row col-12 justify-content-evenly m-auto'>
              <button type="submit" className="btn-goldenleaf text-center col-3 mx-2 mt-4">
                <span className="text-center">Envoyer</span>
              </button>
            </div>
          </AnimatedBtn>
          <ReCAPTCHA
            className='m-auto mt-2 justify-content-center d-flex'
            size="invisible"
            sitekey={process.env.REACT_APP_SITE_KEY}
            ref={recaptchaRef}
          />
          <p className='text-danger mt-1 validation-p'>{validation}</p>
          <p className='text-success mt-1 validation-p'>{success}</p>
        </form>
      </div>
    </AnimatedSubPage>
  )
}