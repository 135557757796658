import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  doc,
  collection,
  getDoc,
  updateDoc
} from 'firebase/firestore';
import { db } from '../../firebase-config';

export const addCartItem = createAsyncThunk(
  'cart/addCartItem',
  async ({ cartItem, uid }, { getState, rejectWithValue }) => {
    const state = getState().cart;
    const updatedItems = [...state.cartItems, cartItem];
    if (uid) {
      try {
        const userRef = doc(collection(db, 'users'), uid);
        await updateDoc(userRef, { products: updatedItems });
        return updatedItems;
      } catch (error) {
        console.error();
        return rejectWithValue(error.message);
      }
    }
    if(!uid) {
      localStorage.setItem('cartItems', JSON.stringify(updatedItems));
      return updatedItems;
    }
  }
);


export const fetchCartItems = createAsyncThunk(
  'cart/fetchCartItems',
  async (uid, { rejectWithValue }) => {
    try {
      let items = [];
      if (uid) {
        const userRef = doc(collection(db, 'users'), uid);
        const snapshot = await getDoc(userRef);
        if (snapshot.exists()) {
          const productsList = snapshot.data().products;
          Object.entries(productsList).forEach(([key, value]) => {
            items.push({ key, ...value });
          });
        }
      } else {
        const storedCartItems = localStorage.getItem('cartItems');
        if (storedCartItems) {
          items = JSON.parse(storedCartItems);
        }
      }
      return items;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateCartItemQuantity = createAsyncThunk(
  'cart/updateCartItemQuantity',
  async ({ itemId, increment, uid }, { getState, rejectWithValue }) => {
    const state = getState().cart;
    let updatedItems = state.cartItems.map(item => {
      if (item.cartId === itemId) {
        const newQuantity = item.quantity + (increment ? 1 : -1);
        return newQuantity <= 0 ? null : { ...item, quantity: newQuantity };
      } else {
        return item;
      }
    }).filter(item => item !== null);

    if (uid) {
      try {
        const userRef = doc(collection(db, 'users'), uid);
        await updateDoc(userRef, { products: updatedItems });
        return updatedItems;
      } catch (error) {
        console.error();
        return rejectWithValue(error.message);
      }
    }
    if (!uid) {
      localStorage.setItem('cartItems', JSON.stringify(updatedItems));
      return updatedItems;
    }
  }
);

const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    cartItems: [],
    countItems: 0,
    loaded: false,
    status: 'idle',
    error: null,
  },
  reducers: {
    setCartItems: (state, action) => {
      state.cartItems = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCartItems.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCartItems.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.loaded = true;
        state.cartItems = action.payload;
        state.countItems = action.payload.length;
      })
      .addCase(fetchCartItems.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(addCartItem.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addCartItem.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.cartItems = action.payload;
        state.countItems++;
      })
      .addCase(addCartItem.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(updateCartItemQuantity.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.cartItems = action.payload;
        state.countItems = action.payload.length;
      });
  }
});

export default cartSlice.reducer;
