import { motion } from 'framer-motion'

const AnimatedSubPage = ({ children }) => {

const animation = {
    initial: {
        y: -400,
        opacity: 0
    },
    animate: {
        y: 0,
        opacity: 1,
        transition: {
            delay: 0.1,
            duration: 0.5
        }
    },
    exit: {
        x: -400,
        opacity: 0,
    },
}

  return ( 
    <motion.div
    variants={animation} 
    initial="initial"
    animate="animate"
    exit="exit">
        {children}
    </motion.div>
  )
}

export default AnimatedSubPage;