import React from 'react'
import { Outlet } from 'react-router-dom'
import AnimatedPage from '../AnimatedPage'

export default function AdminPage() {
  return (
    <AnimatedPage>
        <div>
            <h1 className="text-center m-5 text-light mb-5 shadow-text">Zone Administrateur</h1>
            <Outlet />
        </div>
    </AnimatedPage>
  )
}
