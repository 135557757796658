import React, {useContext, useRef, useState} from 'react'
import { Link } from 'react-router-dom'
import { UserContext } from '../Context/UserContext'
import { useNavigate } from 'react-router-dom'
import AnimatedSubPage from '../AnimatedSubPage';
import AnimatedBtn from '../AnimatedBtn';
import ReCAPTCHA from "react-google-recaptcha";

export default function LogIn() {

  const {signIn} = useContext(UserContext);
  const navigate = useNavigate();
  const [validation, setValidation] = useState("");
  const inputs = useRef([]);
  const formRef = useRef();
  const recaptchaRef = React.useRef();

  const addInputs = el => {
    if(el && !inputs.current.includes(el)) {
      inputs.current.push(el);
    }
  }

  const handleForm = async (e) => {
    e.preventDefault()
    const token = await recaptchaRef.current.executeAsync();
    if (token) {
      try{
        await signIn(
          inputs.current[0].value,
          inputs.current[1].value
        )
        formRef.current.reset();
        setValidation("");
        navigate("/user/profile");
      } catch {
        setValidation("Votre adresse e-mail ou votre mot de passe est incorrect.")
      }
    } else {
      setValidation("Erreur de captcha, veuillez réessayer.")
    }
  }

  return (
    <AnimatedSubPage>
        <div>
            <h2 className="text-center mt-5 text-light shadow-text">Connexion</h2>
        </div>
        <div className="row text-light text-center mb-2 shadow-text">
          <form 
          ref={formRef}
          onSubmit={handleForm}
          className='p-3 px-5'>
            <div className="form-group p-2">
              <label htmlFor="email">Adresse e-mail</label>
              <input
              ref={addInputs}
              type="email" 
              className="form-control" 
              id="email" 
              aria-describedby="emailHelp" />
              <small id="emailHelp" className="form-text">L'adresse e-mail renseignée lors de votre inscription.</small>
            </div>
            <div className="form-group p-2">
              <label htmlFor="pass">Mot de passe</label>
              <input 
              ref={addInputs}
              type="password" 
              className="form-control" 
              id="pass" />
            </div>
            <p className='text-danger mt-1 validation-p'>{validation}</p>
            <div className='d-flex flex-row justify-content-between'>
              <Link to="/authentication/Registration" className='m-3 nav-link-style'>Pas encore de compte ?</Link>
              <Link to="/authentication/AskNewPass" className='m-3 nav-link-style'>Mot de passe oublié</Link>
            </div>
            <AnimatedBtn>
              <button type="submit" className="btn-goldenleaf text-center mx-auto mt-4">
                  <span className="text-center">Connexion</span>
              </button>
            </AnimatedBtn>
            <ReCAPTCHA
              className='m-auto mt-2 justify-content-center d-flex'
              size="invisible"
              sitekey={process.env.REACT_APP_SITE_KEY}
              ref={recaptchaRef}
            />           
          </form>
        </div>
    </AnimatedSubPage>
  )
}
