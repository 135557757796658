import React, { useContext } from 'react'
import { UserContext } from '../Context/UserContext';
import { Outlet, Navigate } from 'react-router-dom';
import AnimatedPage from '../AnimatedPage'

export default function User() {
  
  const {currentUser} = useContext(UserContext);

  if(!currentUser) {
    return <Navigate to="/" />
  }

  return (
    <AnimatedPage>
      <div>
          <h1 className="text-center mt-4 text-light shadow-text">Votre Compte</h1>
          <Outlet />
      </div>
      </AnimatedPage>
  )
}
