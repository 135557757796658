import React, {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom'
import Logo from '../assets/img/logo.webp'
import './Nav.css';
import { auth } from '../firebase-config';
import { signOut } from 'firebase/auth';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AnimatedNavbar from '../AnimatedNavbar'
import { collection, doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase-config';
import { useSelector, useDispatch } from 'react-redux';
import { setShow } from '../redux/slicer/modalSlice'

library.add(fas); // ajouter toutes les icônes de Font Awesome Solid

export default function Nav() {

    const dispatch = useDispatch();
    const adminIdCollection = collection(db, 'rank_admin');
    const adminDoc = doc(adminIdCollection, '1');
    const [adminMail, setAdminMail] = useState(null);
    const countProducts = useSelector((state) => state.cart.countItems)
    getDoc(adminDoc).then((docSnapshot) => {
        if (docSnapshot.exists()) {
            const result = docSnapshot.get('e-mail');
            setAdminMail(result)
        }
    });

    const showModal = () => {
        dispatch(setShow(true))
    }

    const [user, setUser] = useState(null);
    const navigate = useNavigate();
    const logOut = async () => {
        try{
        await signOut(auth)
        navigate('/')
        } catch{
        alert("La déconnexion est impossible pour le moment, veuillez réessayer et vérifier votre connexion internet.")
        }
    }
    useEffect(() => {
        auth.onAuthStateChanged((user) => {
        setUser(user);
        });
    }, []);

    function LogInIcon() {
        return <div className="d-flex justify-content-end">
            <Link className='nav-link d-flex justify-content-end px-2' to="/authentication/LogIn"><FontAwesomeIcon icon="fa-solid fa-user fa-1x" /></Link>
        </div>
    }
    function LogOutIcon() {
        return <div className="d-flex justify-content-end">
            <Link className='nav-link d-flex justify-content-end px-2' to="/user/profile"><FontAwesomeIcon icon="fa-solid fa-user fa-1x" /></Link>
            <Link className='nav-link d-flex justify-content-end px-2 logOut' onClick={logOut}><FontAwesomeIcon icon="fa-solid fa-right-from-bracket fa-1x" /></Link>
        </div>
    }
    function AdminSpace() {
        if(user.email === adminMail) {
            return <div className="d-flex justify-content-end">
            <Link className='nav-link d-flex justify-content-end px-2' to="/admin/AddProduct"><FontAwesomeIcon icon="fa-solid fa-crown" /></Link>
        </div>
        }
    }
  return (
    <AnimatedNavbar>
        <nav className="navbar bg-navbar-dark navbar-dark justify-content-between p-0">
            <div className="d-flex justify-content-start align-items-center nav-width-equal">
                <Link to="/" className='d-flex justify-content-start w-100 mt-2'><img src={Logo} alt="logo" /></Link>
            </div>
            <ul className="navbar-nav d-flex flex-row mx-auto nowrap flex-direction">
                <li className="nav-item p-2">
                    <Link className='nav-link' to="/shop">Boutique</Link>
                </li>
            </ul>
            <div className="d-flex justify-content-end nav-width-equal m-2 p-1 align-items-center">
                <div className='d-flex align-items-center justify-content-center'>
                <button
                    onClick={showModal}
                    className='nav-link no-css-button d-flex justify-content-end pl-0 pr-2 icon-golden'>
                    <FontAwesomeIcon icon={["fa-solid", "fa-basket-shopping"]} />
                </button>
                <p className='numberItems'>{countProducts}</p>
                </div>
                {!user ? <LogInIcon /> : <LogOutIcon />}
                {user ? <AdminSpace /> : null}
            </div>
        </nav>
    </AnimatedNavbar>
  )
}
