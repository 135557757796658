import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { db } from '../../firebase-config';
import { doc, getDoc, updateDoc } from 'firebase/firestore';

export const deleteAddress = createAsyncThunk(
  'address/deleteAddress',
  async ({ userId, addressId }, { rejectWithValue }) => {
    try {
      const userDoc = doc(db, 'users', userId);
      const userSnap = await getDoc(userDoc);
      if (!userSnap.exists()) {
        throw new Error('User not found');
      }

      const user = userSnap.data();
      const updatedAddress = user.address.filter(address => address.id !== addressId);

      await updateDoc(userDoc, {
        address: updatedAddress
      });

      return addressId;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

const addressSlice = createSlice({
  name: 'address',
  initialState: [],
  reducers: {
    updateAddress: (state, action) => {
      return action.payload;
    },
    deleteAddress: (state, action) => {
      return state.filter(address => address.id !== action.payload);
    },
  },
});

export const { updateAddress } = addressSlice.actions;

export default addressSlice.reducer;

