import { createContext, useEffect, useState } from "react";
import {
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    onAuthStateChanged,
    sendPasswordResetEmail,
    confirmPasswordReset,
    updateProfile
} from "firebase/auth";
import {auth} from "../firebase-config";

export const UserContext = createContext()

export function UserContextProvider(props) {

    const [currentUser, setCurrentUser] = useState();
    const [loadingData, setLoadingData] = useState(true);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setCurrentUser(currentUser);
            setLoadingData(false);
        })

        return unsubscribe;

    }, [])

    const signUp = (email, pwd) => createUserWithEmailAndPassword(auth, email, pwd);
    const signIn = (email, pwd) => signInWithEmailAndPassword(auth, email, pwd);
    const askNewPass = (email) => sendPasswordResetEmail(auth, email);
    const resetPass = (code, pwd) => confirmPasswordReset(auth, code, pwd);
    const setName = (displayName) => updateProfile(auth.currentUser, displayName);

    return (
        <UserContext.Provider value={{signUp, signIn, askNewPass, resetPass, setName, currentUser}}>
            {!loadingData && props.children}
        </UserContext.Provider>
    )
}