import React from 'react'
import { Outlet } from 'react-router-dom'
import AnimatedPage from '../AnimatedPage'

export default function Authentication() {
  return (
    <AnimatedPage>
      <div key='Auth'>
          <h1 className="text-center m-5 text-light mb-5 shadow-text">Authentification</h1>
          <Outlet />
      </div>
    </AnimatedPage>
  )
}
