import React, {useState} from 'react'
import AnimatedSubPage from '../AnimatedSubPage'
import AnimatedBtn from '../AnimatedBtn'
import {collection, addDoc } from 'firebase/firestore';
import { db } from '../firebase-config';

export default function Product() {


    const [validation, setValidation] = useState("");
    const [success, setSuccess] = useState("");
    const [nom, setNom] = useState('');
    const [description, setDescription] = useState('');
    const [prix, setPrix] = useState('');
    const [stock, setStock] = useState("");
    const [category, setCategory] = useState("");

    const AjouterProduit = async (event) => {
        setValidation('');
        setSuccess('');
        event.preventDefault();
    
        try {
        const produitRef = await addDoc(collection(db, 'produits'), {
            nom,
            description,
            prix: parseFloat(prix),
            stock: parseFloat(stock),
            category
        });
        setSuccess(`Produit ajouté avec l'ID : ${produitRef.id}`);
        } catch (error) {
        setValidation(`Erreur lors de l'ajout du produit : ${error}`);
        }
    
        setNom('');
        setDescription('');
        setPrix('');
        setStock('');
        setCategory('');
    };

  return (
    <AnimatedSubPage>
        <div>
            <h2 className="text-center mt-5 text-light shadow-text">Ajouts de produits</h2>
        </div>
        <div className="row text-light text-center mb-2 shadow-text">
          <form 
          onSubmit={AjouterProduit}
          className='p-3 px-5'>
            <div className="form-group p-2">
              <label htmlFor="title">Titre du produit</label>
              <input
              type="text" 
              className="form-control" 
              id="title"
              value={nom}
              onChange={(e) => setNom(e.target.value)}
              aria-describedby="emailHelp" />
            </div>
            <div className="form-group p-2">
              <label htmlFor="description">Description</label>
              <input 
              type="text" 
              className="form-control" 
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              id="description" />
            </div>
            <div className="form-group p-2">
              <label htmlFor="price">Prix</label>
              <input 
              type="text" 
              className="form-control" 
              value={prix}
              onChange={(e) => setPrix(e.target.value)}
              id="price" />
            </div>
            <div className="form-group p-2">
              <label htmlFor="stock">Quantité (en gr.)</label>
              <input 
              type="text" 
              className="form-control" 
              value={stock}
              onChange={(e) => setStock(e.target.value)}
              id="stock" />
            </div>
            <div className="form-group p-2">
              <label htmlFor="category">Catégorie</label>
              <select
                className="form-select"
                id="category"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                <option value="" disabled>Choisir une catégorie</option>
                <option value="HHCFlower">Fleur HHC</option>
                <option value="CBDFlower">Fleur CBD</option>
                <option value="HHCResin">Résine HHC</option>
                <option value="CBDResin">Résine CBD</option>
              </select>
            </div>
            <p className='text-danger mt-1 validation-p'>{validation}</p>
            <p className='text-success mt-1 validation-p'>{success}</p>
            <AnimatedBtn>
              <button type="submit" className="btn-goldenleaf text-center mx-auto mt-4">
                  <span className="text-center">Ajout du produit</span>
              </button>
            </AnimatedBtn>        
          </form>
        </div>
    </AnimatedSubPage>
  )
}
