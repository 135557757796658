import React, { useContext } from 'react'
import AnimatedSubPage from '../AnimatedSubPage';
import AnimatedBtn from '../AnimatedBtn';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateAddress, deleteAddress } from '../redux/slicer/addressSlice';
import { v4 as uuidv4 } from 'uuid';
import addressImg from '../assets/img/address.webp';
import {
  collection,
  onSnapshot,
  query,
  getDoc,
  updateDoc,
  doc
} from 'firebase/firestore';
import { db } from '../firebase-config';
import { UserContext } from '../Context/UserContext'

export default function Address() {

    const verticalScrollbarStyle = {
        backgroundColor: 'rgba(238, 203, 113, 0.863)',
        borderRadius: '10px',
        marginRight: "0!important",
        marginBottom: "0!important"
    };

    const [validation, setValidation] = useState();
    const [success, setSuccess] = useState();

    const [formData, setFormData] = useState({
      name: '',
      phone: '',
      address: '',
      zip: '',
      city: '',
      info: ''
    });
    const address = useSelector((state) => state.address);
    const {currentUser} = useContext(UserContext);
    const uid = currentUser ? currentUser.uid : null
    const [btnTrigger, setTrigger] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
      if (uid) { // Vérifiez si currentUser est défini
        const q = query(collection(db, 'users'));
        const unsubscribe = onSnapshot(q, (snapshot) => {
          const addressList = [];
          snapshot.forEach((doc) => {
            if(doc.id === uid) {
                addressList.push({ id: doc.id, ...doc.data() });
            }
          });
          dispatch(updateAddress(addressList));
        });
        return unsubscribe;
      }
    }, [uid, dispatch]);

    const handleDelete = (id) => {
      dispatch(deleteAddress({ userId: uid, AddressId: id }));
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      setSuccess('');
      setValidation('');
      const { name, phone, address, zip, city, info } = formData;
      const alphaChars = name.match(/[a-z]/gi);
      const substrings = name.split(' ');
      if (phone.length !== 10 || !/^\d+$/.test(phone)) {
        setValidation('Le numéro de téléphone dois être composé de 10 chiffres.');
        return;
      }
      if (substrings.length < 2) {
        setValidation("Le champs doit contenir le Nom et le Prénom.");
        return;
      }
      if (!alphaChars || alphaChars.length < 5) {
        setValidation('Le nom doit contenir au moins 5 caractères alphabétiques.');
        return;
      }
      if (address.length < 8){
        setValidation('L\'adresse doit contenir plus de 8 caractères.');
        return;
      }
      if (zip.length !== 5 || !/^\d+$/.test(zip)) {
        setValidation('Le code postal doit contenir 5 chiffres numériques.');
        return;
      }
      if (name && phone && address && zip && city) {
        try {
            const newAddress = {
                id: uuidv4(),
                name,
                phone,
                address,
                zip,
                city,
                info
            };
            const userDocRef = doc(db, 'users', uid); // Référence du document utilisateur
            const userDoc = await getDoc(userDocRef); // Récupérer le document utilisateur
            if (userDoc.exists()) {
                const userData = userDoc.data(); // Données de l'utilisateur
                const updatedAddress = [...userData.address, newAddress]; // Ajouter la nouvelle carte au tableau de cartes existant
                await updateDoc(userDocRef, { address: updatedAddress }); // Mettre à jour le document utilisateur avec le nouveau tableau de cartes
                setFormData({
                    name: '',
                    phone: '',
                    address: '',
                    zip: '',
                    city: '',
                    info: ''
                });
                setValidation('');
                setSuccess("Votre carte à bien été ajoutée !");
                setTimeout(() => {
                  setTrigger(false)
                  setSuccess("");
                }, 1500);
            } else {
                console.error('User document not found');
            }
        } catch (error) {
            console.error('Error updating user document: ', error);
        }
    }
    };

    return (
        <AnimatedSubPage>
        <div className='container products-overflow text-light shadow-text mt-3'>
          <Scrollbars style={{ width: '100%', height: '60vh'}} renderThumbVertical={({ style, ...props }) =>
            <div {...props} style={{ ...style, ...verticalScrollbarStyle }} />
            }>
            {btnTrigger === false && <div className="row text-light text-center mb-2 shadow-text">
              <h2>Vos adresses de livraison</h2>
              <AnimatedBtn>
                <button type="button" className="btn-goldenleaf text-center mx-auto mt-4" onClick={() => setTrigger(true)}>
                  <span className="text-center fs-4">Nouvelle adresse</span>
                </button>
              </AnimatedBtn>
              <div className='row m-auto'>
                {address.map((userData, index) => (
                  <div key={index}>
                    {userData.address.map((address, addressIndex) => (
                        <div key={addressIndex} className='text-center text-light col-12 row justify-content-center my-5 mx-auto'>
                        <div className='d-flex flex-column col-md-4 d-md-block d-none justify-content-center align-items-center m-auto'>
                          <img src={addressImg} height="50%" width="50%" alt='card' className='addressImg'/>
                        </div>
                        <div className=' d-flex flex-column col-md-6 col-12 justify-content-center align-items-center justify-content-md-start align-items-md-start m-auto'>
                          <h5>Nom : <strong>{address.name}</strong></h5>
                          <h5>Numéro de téléphone : <strong>{address.phone}</strong></h5>
                          <h5>Adresse : <strong>{address.address}</strong></h5>
                          <h5>Code Postal : <strong>{address.zip}</strong></h5>
                          <h5>Ville : <strong>{address.city}</strong></h5>
                          <h5>Informations Complémentaires : <strong>{address.info}</strong></h5>
                        </div>
                        <div className='col-md-2 col-12 justify-content-center text-center m-auto d-flex flex-md-column flex-row'>
                          <h2 className='justify-content-center modal-btn-gold font-title my-3 align-items-start pointer' onClick={() => handleDelete(address.id)}><strong>Supprimer</strong></h2>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>}
            {btnTrigger === true && <div className="row text-light text-center mb-2 shadow-text">
            <h2 className="text-center text-light mb-2 shadow-text">Ajouter une nouvelle adresse</h2>
              <form
              onSubmit={handleSubmit}
              className='p-3 px-5'>
                <div className="form-group p-2">
                  <label htmlFor="name">Nom Complet</label>
                  <input
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  required
                  type="text" 
                  className="form-control" 
                  id="name" 
                  aria-describedby="nameHelp"
                  placeholder='Nom et Prénom'/>
                </div>
                <div className="form-group p-2">
                  <label htmlFor="phone">Numéro de téléphone</label>
                  <input
                  onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                  required
                  type="text" 
                  className="form-control"
                  aria-describedby="phoneHelp"
                  id="phone"/>
                </div>
                <div className="form-group p-2">
                  <label htmlFor="adress">Adresse</label>
                  <input
                  onChange={(e) => setFormData({ ...formData, address: e.target.value })}
                  required
                  placeholder="Numéro, rue, extension, appartement, étage, etc..."
                  type="text" 
                  className="form-control"
                  id="adress"/>
                </div>
                <div className="form-group p-2">
                  <label htmlFor="zipCode">Code postal</label>
                  <input 
                  onChange={(e) => setFormData({ ...formData, zip: e.target.value })}
                  required
                  placeholder="XXXXX"
                  type="text" 
                  className="form-control"
                  id="zipCode"/>
                </div>
                <div className="form-group p-2">
                  <label htmlFor="city">Ville</label>
                  <input 
                  onChange={(e) => setFormData({ ...formData, city: e.target.value })}
                  required
                  type="text" 
                  className="form-control"
                  id="city"/>
                </div>
                <div className="form-group p-2">
                  <label htmlFor="additionnal">Informations Complémentaires(facultatif)</label>
                  <textarea 
                  onChange={(e) => setFormData({ ...formData, info: e.target.value })}
                  required
                  placeholder="Emplacement précis de la boite aux lettres, code d'accès, etc..."
                  type="text" 
                  className="form-control"
                  id="additionnal"/>
                </div>
                <AnimatedBtn>
                  <div className='row col-12 justify-content-evenly m-auto'>
                    <button type="button" className="btn-goldenleaf text-center col-3 mx-2 mt-4" onClick={() => setTrigger(false)}>
                        <span className="text-center">Retour</span>
                    </button>
                    <button type="submit" className="btn-goldenleaf text-center col-3 mx-2 mt-4">
                        <span className="text-center">Enregistrer</span>
                    </button>
                  </div>
                </AnimatedBtn>
                <p className='text-danger mt-1 validation-p'>{validation}</p>
                <p className='text-success mt-1 validation-p'>{success}</p>       
              </form>
            </div>}
          </Scrollbars>
        </div>   
      </AnimatedSubPage>
    );
}