import { signOut } from 'firebase/auth';
import { UserContext } from '../Context/UserContext';
import React, { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { auth } from '../firebase-config';
import AnimatedSubPage from '../AnimatedSubPage';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

library.add(fas); // ajouter toutes les icônes de Font Awesome Solid

export default function Profile() {

  const {currentUser} = useContext(UserContext);
  const verticalScrollbarStyle = {
    backgroundColor: 'rgba(238, 203, 113, 0.863)',
    borderRadius: '10px',
    marginRight: "0!important",
    marginBottom: "0!important"
  };

  const navigate = useNavigate();
  const logOut = async () => {
    try{
      await signOut(auth)
      navigate("/");
    } catch{
      alert("La déconnexion est impossible pour le moment, veuillez réessayer et vérifier votre connexion internet.")
    }
  }
  

  return (
    <AnimatedSubPage>
      <h2 className="text-center text-light mb-2 shadow-text">Bonjour {currentUser.displayName}</h2>
      <div className='container products-overflow text-light shadow-text mt-3 profile-card'>
        <Scrollbars autoHeight autoHeightMin="10vh" autoHeightMax="80vh" style={{ width: '100%'}} renderThumbVertical={({ style, ...props }) =>
          <div {...props} style={{ ...style, ...verticalScrollbarStyle }} />
        }>
          <div className="row row-cols-1 row-cols-lg-2 g-4 mx-3 card-profile mt-1 justify-content-center mx-auto">
            <Link to="/user/registeredcards" className='col'>
              <div className='card h-100'>
                <div className="row g-0">
                  <div className="col-sm-3 d-flex justify-content-center p-3" style={{color: '#ddd18f'}}>
                    <FontAwesomeIcon icon={['fa-solid', 'fa-credit-card']} size="3x" style={{transform: 'rotate(-20deg)'}} />
                  </div>
                  <div className="col-sm-9">
                    <div className="card-body">
                      <h5 className="user-card-title">Vos moyens de paiements</h5>
                      <p className="card-text">Trouver tous les paiements effectués et les cartes enregistrées.</p>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="/user/address" className='col'>
              <div className='card h-100'>
                <div className="row g-0">
                  <div className="col-sm-3 d-flex justify-content-center p-3" style={{color: '#ddd18f'}}>
                    <FontAwesomeIcon icon={['fa-solid', 'fa-building-user']} size="3x" style={{transform: 'rotate(-20deg)'}} />
                  </div>
                  <div className="col-sm-9">
                    <div className="card-body">
                      <h5 className="user-card-title">Vos Adresses</h5>
                      <p className="card-text">Modifier/Retirer des adresses de receptions et de facturations.</p>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
            <div className='col'>
              <div className='card h-100'>
                <div className="row g-0">
                  <div className="col-sm-3 d-flex justify-content-center p-3" style={{color: '#ddd18f'}}>
                    <FontAwesomeIcon icon={['fa-solid', 'fa-truck-fast']} size="3x" style={{transform: 'rotate(-20deg)'}} />
                  </div>
                  <div className="col-sm-9">
                    <div className="card-body">
                      <h5 className="user-card-title">Vos Commandes</h5>
                      <p className="card-text">Suivre toutes les commandes passées et en cours.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Link to="/user/help" className='col'>
              <div className='card h-100'>
                <div className="row g-0">
                  <div className="col-sm-3 d-flex justify-content-center p-3" style={{color: '#ddd18f'}}>
                    <FontAwesomeIcon icon={['fa-solid', 'fa-headphones']} size="3x" style={{transform: 'rotate(-20deg)'}} />
                  </div>
                  <div className="col-sm-9">
                    <div className="card-body">
                      <h5 className="user-card-title">Votre service d'aide & support</h5>
                      <p className="card-text">Contacter l'équipe de support en quelques secondes.</p>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <button onClick={logOut} className="m-3 no-css-btn text-golden" style={{width: "5rem", fontSize: "2rem", padding: "0"}}>
            <FontAwesomeIcon icon={['fas', 'power-off']} size="1x" />
          </button>
        </Scrollbars>
      </div>   
    </AnimatedSubPage>
  )
}
