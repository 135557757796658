import React, { useState, useEffect } from 'react';
import { collection, query, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase-config';
import { ObiWanFlower, JackHererFlower, LemonHazeFlower, BlueBerryFlower, AmnesiaFlower } from '../productImage';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AnimatedSlider from '../AnimatedSlider';

export default function ProductNavbar() {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const q = query(collection(db, 'produits'));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const productsList = [];
      snapshot.forEach((doc) => {
        productsList.push({ id: doc.id, ...doc.data() });
      });
      setProducts(productsList);
    });
    return unsubscribe;
  }, []);

  const productImage = (product) => {
    const name = product.pictureName;

    switch (name) {
      case 'ObiWanFlower':
        return ObiWanFlower;
      case 'JackHererFlower':
        return JackHererFlower;
      case 'LemonHazeFlower':
        return LemonHazeFlower;
      case 'BlueBerryFlower':
        return BlueBerryFlower;
      case 'AmnesiaFlower':
        return AmnesiaFlower;
      default:
        return AmnesiaFlower;
    }
  };

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    cssEase: "ease",
    swipeToSlide: true,
    pauseOnHover: true,
    useTransform: true,
    variableWidth: false,
    responsive: [
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 455,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

return (
    <AnimatedSlider>
        <Slider {...settings} className="slider-product">
            {products.map((product) => (
            <div className="d-flex flex-column justify-content-center" key={product.id}>
                <img className='mx-auto rotate-15' style={{height: "6rem"}} src={productImage(product)} alt={product.nom} />
                <p className='text-light m-0'>{product.nom}</p>
            </div>
            ))}
        </Slider>
    </AnimatedSlider>
    );
}
