import React, {useContext, useRef, useState} from 'react'
import { UserContext } from '../Context/UserContext'
import AnimatedBtn from '../AnimatedBtn';
import AnimatedSubPage from '../AnimatedSubPage';
import ReCAPTCHA from "react-google-recaptcha";

export default function AskNewPass() {
  const {askNewPass} = useContext(UserContext);
  const [validation, setValidation] = useState("");
  const inputs = useRef([]);
  const formRef = useRef();
  const [success, setSuccess] = useState();
  const recaptchaRef = React.useRef();

  const addInputs = el => {
    if(el && !inputs.current.includes(el)) {
      inputs.current.push(el);
    }
  }

  const handleForm = async (e) => {
    e.preventDefault()
    const token = await recaptchaRef.current.executeAsync();
    setSuccess("");
    setValidation("");
    if (token) {
      try{
          await askNewPass(inputs.current[0].value)
          setSuccess("Un e-mail vient d'être envoyé a l'adresse renseignée.");
      } catch {
          setValidation("L'adresse e-mail renseigné est incorrect.");
      }
    } else {
      setValidation("Erreur de captcha, veuillez réessayer.")
    }
  }

  return (
    <AnimatedSubPage>
        <div>
            <h2 className="text-center mt-5 text-light shadow-text">Récupération de mot de passe</h2>
        </div>
        <div className="row text-light text-center mb-2 shadow-text">
          <form 
          ref={formRef}
          onSubmit={handleForm}
          className='p-3 px-5'>
            <div className="form-group p-2">
              <label htmlFor="email">Adresse e-mail</label>
              <input
              ref={addInputs}
              type="email" 
              className="form-control" 
              id="email" 
              aria-describedby="emailHelp" />
              <small id="emailHelp" className="form-text text-muted">L'adresse e-mail renseignée lors de votre inscription.</small>
            </div>
            <p className='text-danger mt-1 validation-p'>{validation}</p>
            <p className='text-success mt-1 validation-p'>{success}</p>
            <AnimatedBtn>
              <button type="submit" className="btn-goldenleaf text-center mx-auto mt-4">
                  <span className="text-center">Valider</span>
              </button>
            </AnimatedBtn>
            <ReCAPTCHA
              className='m-auto mt-2 justify-content-center d-flex'
              size="invisible"
              sitekey={process.env.REACT_APP_SITE_KEY}
              ref={recaptchaRef}
            />        
          </form>
        </div>
    </AnimatedSubPage>
  )
}
