import React, { useContext } from 'react'
import AnimatedSubPage from '../AnimatedSubPage';
import AnimatedBtn from '../AnimatedBtn';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateCards, deleteCard } from '../redux/slicer/cardsSlice';
import { v4 as uuidv4 } from 'uuid';
import cardImg from '../assets/img/card.webp';
import {
  collection,
  onSnapshot,
  query,
  getDoc,
  updateDoc,
  doc
} from 'firebase/firestore';
import { db } from '../firebase-config';
import { UserContext } from '../Context/UserContext'

export default function RegisteredCards() {

    const verticalScrollbarStyle = {
        backgroundColor: 'rgba(238, 203, 113, 0.863)',
        borderRadius: '10px',
        marginRight: "0!important",
        marginBottom: "0!important"
    };

    const [validation, setValidation] = useState();
    const [success, setSuccess] = useState();

    const [formData, setFormData] = useState({
      cardNumber: '',
      name: '',
      expDate: '',
      cvv: ''
    });
    const cards = useSelector((state) => state.cards);
    const {currentUser} = useContext(UserContext);
    const uid = currentUser ? currentUser.uid : null
    const [btnTrigger, setTrigger] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
      if (uid) { // Vérifiez si currentUser est défini
        const q = query(collection(db, 'users'));
        const unsubscribe = onSnapshot(q, (snapshot) => {
          const cardsList = [];
          snapshot.forEach((doc) => {
            if(doc.id === uid) {
              cardsList.push({ id: doc.id, ...doc.data() });
            }
          });
          dispatch(updateCards(cardsList));
        });
        return unsubscribe;
      }
    }, [uid, dispatch]);

    const handleDelete = (id) => {
      dispatch(deleteCard({ userId: uid, cardId: id }));
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      setSuccess('');
      setValidation('');
      const { cardNumber, name, expDate, cvv } = formData;
      const alphaChars = name.match(/[a-z]/gi);
      const substrings = name.split(' ');
      if (cardNumber.length !== 16 || !/^\d+$/.test(cardNumber)) {
        setValidation('Le numéro de carte doit contenir 16 chiffres numériques.');
        return;
      }
      if (substrings.length < 2) {
        setValidation("Le champs doit contenir le Nom et le Prénom.");
        return;
      }
      if (!alphaChars || alphaChars.length < 5) {
        setValidation('Le nom doit contenir au moins 5 caractères alphabétiques.');
        return;
      }
      if (expDate.length !== 5 || !expDate.includes('/')){
        setValidation('La date d\'expiration doit être au format MM/AA.');
        return;
      }
      if (cvv.length !== 3 || !/^\d+$/.test(cvv)) {
        setValidation('Le code de sécurité doit contenir 3 chiffres numériques.');
        return;
      }
      if (cardNumber && name && expDate && cvv) {
        try {
            const newCard = {
                id: uuidv4(),
                cardNumber,
                name,
                expDate,
                cvv
            };
            const userDocRef = doc(db, 'users', uid); // Référence du document utilisateur
            const userDoc = await getDoc(userDocRef); // Récupérer le document utilisateur
            if (userDoc.exists()) {
                const userData = userDoc.data(); // Données de l'utilisateur
                const updatedCards = [...userData.cards, newCard]; // Ajouter la nouvelle carte au tableau de cartes existant
                await updateDoc(userDocRef, { cards: updatedCards }); // Mettre à jour le document utilisateur avec le nouveau tableau de cartes
                setFormData({
                    cardNumber: '',
                    name: '',
                    expDate: '',
                    cvv: ''
                });
                setValidation('');
                setSuccess("Votre carte à bien été ajoutée !");
                setTimeout(() => {
                  setTrigger(false)
                  setSuccess("");
                }, 1500);
            } else {
                console.error('User document not found');
            }
        } catch (error) {
            console.error('Error updating user document: ', error);
        }
    }
    };

    return (
      <AnimatedSubPage>
        <div className='container products-overflow text-light shadow-text mt-3'>
          <Scrollbars style={{ width: '100%', height: '60vh'}} renderThumbVertical={({ style, ...props }) =>
            <div {...props} style={{ ...style, ...verticalScrollbarStyle }} />
            }>
            {btnTrigger === false && <div className="row text-light text-center mb-2 shadow-text">
              <h2>Vos moyens de paiement</h2>
              <AnimatedBtn>
                <button type="button" className="btn-goldenleaf text-center mx-auto mt-4" onClick={() => setTrigger(true)}>
                  <span className="text-center fs-4">Ajouter une carte</span>
                </button>
              </AnimatedBtn>
              <div className='row m-auto'>
                {cards.map((userData, index) => (
                  <div key={index}>
                    {userData.cards.map((card, cardIndex) => (
                        <div key={cardIndex} className='text-center text-light col-12 row justify-content-center my-5 mx-auto'>
                        <div className='d-flex flex-column col-md-4 d-md-block d-none justify-content-center align-items-center m-auto'>
                          <img src={cardImg} height="50%" width="50%" alt='card'/>
                        </div>
                        <div className=' d-flex flex-column col-md-6 col-12 justify-content-center align-items-center justify-content-md-start align-items-md-start m-auto'>
                          <h5>Numéro de carte : **** **** **** <strong>{card.cardNumber.toString().slice(0, -12)}</strong></h5>
                          <h5>Nom : <strong>{card.name}</strong></h5>
                          <h5>Date d'expiration : <strong>{card.expDate}</strong></h5>
                        </div>
                        <div className='col-md-2 col-12 justify-content-center text-center m-auto d-flex flex-md-column flex-row'>
                          <h2 className='justify-content-center modal-btn-gold font-title my-3 align-items-start pointer' onClick={() => handleDelete(card.id)}><strong>Supprimer</strong></h2>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>}
            {btnTrigger === true && <div className="row text-light text-center mb-2 shadow-text">
            <h2 className="text-center text-light mb-2 shadow-text">Enregistrer une nouvelle carte</h2>
              <form
              onSubmit={handleSubmit}
              className='p-3 px-5'>
                <div className="form-group p-2">
                  <label htmlFor="cardNumber">Numéro de carte</label>
                  <input
                  onChange={(e) => setFormData({ ...formData, cardNumber: e.target.value })}
                  required
                  type="text" 
                  className="form-control" 
                  id="cardNumber" 
                  aria-describedby="cardHelp"
                  placeholder='Veuillez entrer les 16 Chiffres de votre carte bancaire'/>
                </div>
                <div className="form-group p-2">
                  <label htmlFor="name">Nom sur la carte</label>
                  <input
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  required
                  type="text" 
                  className="form-control"
                  id="name"/>
                </div>
                <div className="form-group p-2">
                  <label htmlFor="expDate">Date d'expiration</label>
                  <input
                  onChange={(e) => setFormData({ ...formData, expDate: e.target.value })}
                  required
                  placeholder="MM/AA"
                  type="text" 
                  className="form-control"
                  id="expDate"/>
                </div>
                <div className="form-group p-2">
                  <label htmlFor="cvv">Code de sécurité (CVV)</label>
                  <input 
                  onChange={(e) => setFormData({ ...formData, cvv: e.target.value })}
                  required
                  placeholder="XXX"
                  type="text" 
                  className="form-control"
                  id="cvv"/>
                </div>
                <AnimatedBtn>
                  <div className='row col-12 justify-content-evenly m-auto'>
                    <button type="button" className="btn-goldenleaf text-center col-3 mx-2 mt-4" onClick={() => setTrigger(false)}>
                        <span className="text-center">Retour</span>
                    </button>
                    <button type="submit" className="btn-goldenleaf text-center col-3 mx-2 mt-4">
                        <span className="text-center">Enregistrer</span>
                    </button>
                  </div>
                </AnimatedBtn>
                <p className='text-danger mt-1 validation-p'>{validation}</p>
                <p className='text-success mt-1 validation-p'>{success}</p>       
              </form>
            </div>}
          </Scrollbars>
        </div>   
      </AnimatedSubPage>
    )
  }