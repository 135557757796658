import { motion } from 'framer-motion'
import { useState, useEffect } from 'react';

const AnimatedNavbar = ({ children }) => {

    const [isFirstLoad, setIsFirstLoad] = useState(true);

    useEffect(() => {
        setIsFirstLoad(false);
    }, []);

    const animation = {
        initial: isFirstLoad ? {y: -200} : {y: 0},
        animate: {y: 0},
        transition: {
            duration: 2,
            type: "spring",
            stiffness: 160,
            damping: 8,
        },
    }

  return ( 
    <motion.div
    variants={animation} 
    initial="initial" 
    animate="animate"
    transition={animation.transition}>
    {children}
    </motion.div>
  )
}

export default AnimatedNavbar;