import { motion } from 'framer-motion'

const AnimatedBtn = ({ children }) => {

const animation = {
    initial: {
        x: -400,
        opacity: 0
    },
    animate: {
        x: 0,
        opacity: 1,
        transition: {
            delay: 0.5,
            duration: 0.5
        }
    },
    exit: {
        opacity: 0,
    },
}

  return ( 
    <motion.div
    variants={animation} 
    initial="initial"
    animate="animate"
    exit="exit">
        {children}
    </motion.div>
  )
}

export default AnimatedBtn;