import React from 'react'
import { Modal, Button } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useDispatch, useSelector } from 'react-redux';
import { setShow } from '../redux/slicer/modalSlice'
import { updateCartItemQuantity } from '../redux/slicer/cartSlice';
import { UserContext } from '../Context/UserContext';
import { useContext } from 'react';
import { ObiWanFlower, JackHererFlower, LemonHazeFlower, BlueBerryFlower, AmnesiaFlower } from '../productImage';

export default function Cart() {
  
  const items = useSelector((state) => state.cart.cartItems);
  const modal = useSelector((state) => state.modal);
  const dispatch = useDispatch();
  const {currentUser} = useContext(UserContext);
  const uid = currentUser ? currentUser.uid : null

  const productImage = (product) => {
    const name = product.pictureName;

    switch (name) {
      case 'ObiWanFlower':
        return ObiWanFlower;
      case 'JackHererFlower':
        return JackHererFlower;
      case 'LemonHazeFlower':
        return LemonHazeFlower;
      case 'BlueBerryFlower':
        return BlueBerryFlower;
      case 'AmnesiaFlower':
        return AmnesiaFlower;
      default:
        return AmnesiaFlower;
    }
  };

  const hideModal = () => {
    dispatch(setShow(false))
  }

  const verticalScrollbarStyle = {
    backgroundColor: 'rgba(238, 203, 113, 0.863)',
    borderRadius: '10px'
  };

  const updateItem = (id, increment) => {
    dispatch(updateCartItemQuantity({ itemId: id, increment: increment, uid: uid }))
  }

  const totalPrice = (unitPrice, quantity, grPrice, discount) => {
    let total;
    total = unitPrice * quantity * grPrice * discount;
    return total.toFixed(2);
  }

  if (items.length === 0) {
    return (
      <Modal show={modal.show} onHide={hideModal} className='bg-modal-dark'>
        <Modal.Header className='d-flex justify-content-center'>
          <Modal.Title className='text-center title-white'>Mon Panier</Modal.Title>
        </Modal.Header>
        <Modal.Body className='d-flex justify-content-center align-items-center'>
          <p className='m-5'>Panier vide</p>
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-between'>
          <Button className='modal-btn-gold font-title' onClick={hideModal}>
            Fermer
          </Button>
          <Button className='modal-btn-gold font-title'>
            Finaliser
          </Button>
        </Modal.Footer>
      </Modal>
    );
  } else {
    return (
      <Modal show={modal.show} onHide={hideModal} className='bg-modal-dark'>
        <Modal.Header className='d-flex justify-content-center'>
          <Modal.Title className='text-center title-white'>Mon Panier</Modal.Title>
        </Modal.Header>
        <Scrollbars autoHeight autoHeightMin="10vh" autoHeightMax="60vh" style={{width: '100%'}} 
          renderThumbVertical={({ style, ...props }) =>
            <div {...props} style={{ ...style, ...verticalScrollbarStyle}}/>
          }
          renderTrackHorizontal={(props) => (
            <div
            {...props} style={{ display: 'none' }}/>
          )}
        >
        {items.map((item) => (
        <Modal.Body key={item.cartId} className='d-flex justify-content-around col-10 mx-auto'>
          <div className='d-flex justify-content-start align-items-center'>
            <button className='font-title btn-modal-size' onClick={() => updateItem(item.cartId, false)}>-</button>
          </div>
          <div className='d-flex justify-content-center align-items-center w-100 mx-auto'>
            <div>
              <img style={{maxWidth: "5rem", maxHeight: "5rem"}} src={productImage(item)} alt="produit" />
            </div>
            <div className='w-100 text-center'>
              <h4 className='m-0 p-0'>{item.nom}</h4>
              <h6 className='m-0 p-0'>Quantité: {item.quantity} x {item.grQuantity}gr.</h6>
              <h6 className='m-0 p-0'>Prix: {totalPrice(item.prix, item.quantity, item.grQuantity, item.reductionPrice)}€</h6>
            </div>
          </div>
          <div className='d-flex justify-content-end align-items-center'>
            <button className='font-title btn-modal-size' onClick={() => updateItem(item.cartId, true)}>+</button>
          </div>
        </Modal.Body>
        ))}
        </Scrollbars>
        <Modal.Footer className='d-flex justify-content-between'>
          <Button className='modal-btn-gold font-title' onClick={hideModal}>
            Fermer
          </Button>
          <Button className='modal-btn-gold font-title'>
          Finaliser
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}